import React, { Component } from 'react';
import AudioHelper from '../helpers/AudioHelper';
import Button from '../shared/Button';
import './Settings.scss';

class SettingsAudio extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.state = {
            audio_volume: {}
        };
        this.audio_helper = new AudioHelper(null);
    }

    componentDidMount() {
        this.setVolume();
    }

    setVolume() {
        this.setState({ audio_volume: this.audio_helper.getVolumeSettings() });
    }

    async volume(setting, change) {
        const vol = this.state.audio_volume[setting];
        if (change === -1 && vol > 1) {
            this.setState(prevState => ({
                audio_volume: {
                  ...prevState.audio_volume,
                  [setting]: vol - 1,
                }
              }));
        } else if (change === 1 && vol < 10) {
            this.setState(prevState => ({
                audio_volume: {
                  ...prevState.audio_volume,
                  [setting]: vol + 1,
                }
              }));
        }

        // Save
        this.props.parent.save();
    }

    render() {

        const { t } = this.props.parent.state.parentComponent.props;

        return (
            <>
            <h3>{ t('settings:audio_levels') }</h3>

            <div className="audio-settings">

                <div>
                    <h4>{ t('settings:audio.spoken_instructions') }</h4>
                    <div className="audio-volume">
                        <Button className={(this.state.audio_volume.spoken_instructions < 2 ? 'gray-light' : 'blue-dark') + ' circle icon minus'} onClick={() => this.volume('spoken_instructions', -1) }></Button>
                        <div className="value">{this.state.audio_volume.spoken_instructions}/10</div>
                        <Button className={(this.state.audio_volume.spoken_instructions === 10 ? 'gray-light' : 'blue-dark') + ' circle icon plus'} onClick={() => { this.volume('spoken_instructions', 1) }}></Button>
                    </div>
                </div>

                <div>
                    <h4>{ t('settings:audio.music') }</h4>
                    <div className="audio-volume">
                        <Button className={(this.state.audio_volume.music === 0 ? 'gray-light' : 'blue-dark') + ' circle icon minus'} onClick={() => this.volume('music', -1) }></Button>
                        <div className="value">{this.state.audio_volume.music}/10</div>
                        <Button className={(this.state.audio_volume.music === 10 ? 'gray-light' : 'blue-dark') + ' circle icon plus'} onClick={() => { this.volume('music', 1) }}></Button>
                    </div>
                </div>

                <div>
                    <h4>{ t('settings:audio.audio_signals') }</h4>
                    <div className="audio-volume">
                        <Button className={(this.state.audio_volume.audio_signals === 0 ? 'gray-light' : 'blue-dark') + ' circle icon minus'} onClick={() => this.volume('audio_signals', -1) }></Button>
                        <div className="value">{this.state.audio_volume.audio_signals}/10</div>
                        <Button className={(this.state.audio_volume.audio_signals === 10 ? 'gray-light' : 'blue-dark') + ' circle icon plus'} onClick={() => { this.volume('audio_signals', 1) }}></Button>
                    </div>
                </div>

            </div>
            </>
        );
    }
}

export default SettingsAudio;