import React, { Component } from 'react';
import i18next from 'i18next';
import FileHelper from '../helpers/FileHelper';
import DataHelper from '../helpers/DataHelper';
import AudioHelper from '../helpers/AudioHelper';
import SystemHelper from '../helpers/SystemHelper';
import './WorkoutIntro.scss';
import VideoTransform from './VideoTransform';
import SubtitlesPlayer from './SubtitlesPlayer';

class ExerciseVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_ready: false,
            is_playing: false,
            is_paused: false,
            subtitles: null
        };
        this.video = {
            elements: [],
            current: null,
            current_index: -1,
            current_time: 0,
            loop_count: 0,
            loop_speed: 1,
            delay_interval: null,
            delay_time: 0,
            start_path: null,
            loop_path: null,
            end_path: null,
            total_duration: 0,
            loop_in_intro: false,
            loop_started: false,
            pref: FileHelper.getPlatformVideoPreferences()
        };
        this.audio = null;

        this.listeners = {
            loop_next: null,
            show_start: null,
            set_interval: null,
        };

        this.config = null;

        this.video_transform = React.createRef();

        this.audio_helper = null;
    }

    /**
     * Load specific video file
     * @param {Index of video array} index
     * @param {Video source} src 
     */
    async loadPart(index, src) {
        return new Promise(async (resolve, reject) => {
            // Set properties if video exists
            // and resolve on loaded
            if (src) {
                let full_src = null;
                // Get blob URL
                await FileHelper.getLocalUrl(src, this.video.pref.mime_type).then((url) => {
                    full_src = url;
                    this.video.elements[index].src = full_src;
                    this.video.elements[index].loop = false;
                    this.video.elements[index].currentTime = 0;

                    this.video.elements[index].addEventListener('loadedmetadata', () => resolve(this.video.elements[index].src));
                    this.video.elements[index].addEventListener('error', (event) => reject(event));

                // Redirect to preload if no local file
                }).catch((error) => {
                    console.log('ERROR', error);
                    // Attach value for redirecting to exercise after preload
                    this.props.ExerciseView.history.push({
                        pathname: '/preload',
                        state: {
                            program: this.props.ExerciseView.state.program
                        }
                    });
                    reject(error);
                });
            }
            // Else resolve directly
            else {
                resolve(null);
            }
        });
    }

    /**
     * Sync audio with video
     */
    syncAudio() {
        // TODO: Test this implementation further
        /*const sync = () => {
            if (!this.state.is_paused && this.audio_helper && this.video.current_index === 0 && this.video.elements[0]) {
                this.audio_helper.getTime().then(audio_time => {
                    const diff = Math.abs(audio_time - this.video.elements[0].currentTime);
                    //console.log('audio diff', diff + (audio_time > this.video.elements[0].currentTime ? ' (audio ahead)' : '(video ahead)'));
                    if (diff > 0.1) { // Allow < 0.1 sec out of sync
                        console.log('Syncing audio');
                        this.video.elements[0].currentTime = audio_time;
                    }
                    // Re-run in a second
                    setTimeout(sync, 1000);
                });
            }
        };

        // Run directly
        if (!SystemHelper.isIos()) {
            sync();
        }*/
    }

    /**
     * Load intro audio file
     * @param {Audio source} src 
     */
    async loadAudio(src) {
        return new Promise(async (resolve, reject) => {
            // Set properties if video exists
            // and resolve on loaded

            if (src) {

                // Use Cordova media plugin for iOS
                if (SystemHelper.isIos()) {

                    console.log('iOS: Using cdvfile and media plugin...');

                    // Use cdvfile
                    const path = SystemHelper.getCdvPath(src);
                    this.audio_helper = new AudioHelper(path);
                    //this.audio_helper.setTime(0);

                    // Set volume of instruction
                    const volume = this.audio_helper.getVolumeSettings().spoken_instructions;
                    this.audio_helper.setVolume(volume / 10);
                    console.log('Set instruction volume to ', volume);

                    resolve(path);

                } else {

                    // Get local storage URL
                    await FileHelper.getLocalUrl(src, 'audio/mpeg').then((url) => {
                        if (url) {
                            this.audio = new Audio(url);
                            this.audio.loop = false;
                            this.audio.addEventListener('error', (event) => reject(event));

                            console.log('Loading audio', url);
                            this.audio_helper = new AudioHelper(this.audio);
                            //this.audio_helper.setTime(0);

                            // Set volume of instruction
                            const volume = this.audio_helper.getVolumeSettings().spoken_instructions;
                            this.audio_helper.setVolume(volume / 10);
                            console.log('Set instruction volume to ', volume);

                            // Resolve when loaded
                            this.audio.addEventListener('loadedmetadata', () => {
                                console.log('Audio file is ready.');
                                console.log('this.audio.src', this.audio.src);
                                //console.log('url', url);
                                resolve(this.audio.src);
                            });
                        } else {
                            console.log('No audio...');
                            this.audio = null;
                            reject(null);
                        }
                    // Redirect to preload if no local file
                    }).catch((error) => {
                        console.log('ERROR', error);
                        alert('Local audio file could not be found, redirecting to preload...');
                        this.props.ExerciseView.history.push('/preload');
                        reject(error);
                    });
                }
            }
            // Else resolve directly
            else {
                resolve(null);
            }
        });
    }

    /**
     * Load all video parts
     * @param {The current exercise object} exercise 
     */
    async load(exercise) {
        const component = this;
        return new Promise(async (resolve, reject) => {
            if (!exercise) {
                reject('No exercise initiated!');
            }
            else {
                // Set local video paths
                const video = {
                    start: exercise.uuid + '_start.' + this.video.pref.extension,
                    loop: exercise.uuid + '_loop.' + this.video.pref.extension
                };

                // Get loop playback rate from speed
                this.video.loop_speed = exercise.speed / 100;

                // Set local audio path
                const audio = exercise.uuid + '_audio_' + i18next.language + '.mp3';

                // If user should have subtitles and exercise has subtitles
                // Set local subtitle path
                if (this.props.Subtitles && exercise.subtitles_loc) {
                    /*await FileHelper.getLocalUrl(exercise.uuid + '_subtitles_' + i18next.language + '.vtt', 'text/vtt').then((url) => {
                        this.setState({ subtitles: url });
                    });
                    */
                   console.log('setting subtitles');
                   this.setState({ subtitles: exercise.uuid + '_subtitles_' + i18next.language + '.vtt' });
                } else {
                    console.log('No subtitles!', exercise);
                }

                // Load start
                component.loadPart(0, video.start).then((start_path) => {
                    component.video.start_path = start_path;
                    // Load loop
                    component.loadPart(1, video.loop).then((loop_path) => {
                        component.video.loop_path = loop_path;
                        // Load audio
                        component.loadAudio(audio).then((audio_path) => {
                            if (audio_path) {
                                //component.audio.src = audio_path;
                                console.log('muting original audio', component.video.elements[0].src);
                                // Mute original video audio
                                component.video.elements[0].muted = true;
                            } else {
                                component.audio = null;
                                component.video.elements[0].muted = false;
                                console.log('UNmuting original audio');
                            }
                            // Resolve when finished
                            component.setState({ is_ready: true });
                            resolve();
                        }).catch((error) => {
                            // Don't mute original audio
                            component.video.elements[0].muted = false;
                            console.log('UNmuting original audio');

                            console.error('ERROR LOADING AUDIO', error);
                            resolve();
                        });
                    }).catch((error) => {
                        console.error('ERROR LOADING LOOP', error);
                        reject(error);
                    });
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });
            }
        });
    }

    /**
     * Start music if activated
     */
    startMusic() {
        // Stop instruction audio
        // and play music
        if (SystemHelper.isIos()) {
            this.audio_helper.stop().then(() => this.props.ExerciseView.playMusic());
        } else {
            this.audio_helper.pause();
            this.props.ExerciseView.playMusic();
        }
    }

    /**
     * Start repetition loop
     */
    async startLoop() {
        console.log('start loop');

        this.setState({ show_start: false, show_loop: true });
        this.video.current = this.video.elements[1];
        this.video.current.currentTime = 0;
        this.video.current_index = 1;

        // Reset tempo for intro
        this.video.elements[0].playbackRate = 1;

        // Play loop
        this.video.current.play();

        // Start music
        this.startMusic();

        // Set rep number 1
        this.props.ExerciseView.setState({ rep_num: (this.video.loop_in_intro ? 2 : 1) });
    }

    /**
     * Delays repetition if set in program
     * and continues to count time
     */
    delayRepetition() {
        const component = this;
        return new Promise((resolve) => {
            let delay_time = 0;
            this.video.delay_interval = setInterval(() => {
                component.video.delay_time += 0.06; // 60 ms
                delay_time += 0.06;
                //console.log('delay time', delay_time);
                //console.log('total delay time', component.video.delay_time);
                if (delay_time >= component.props.Delay) {
                    clearInterval(component.video.delay_interval);
                    resolve();
                }
            }, 60);
        });
    }

    /**
     * Play whole exercise and resolve when finished
     */
    async play() {
        const component = this;
        return new Promise((resolve) => {
            this.video.current_index = 0;
            this.video.current_time = 0;
            this.video.loop_count = 0;
            this.video.total_duration = component.getTotalDuration();
            this.video.loop_in_intro = this.props.LoopInIntro;

            this.listeners.show_start = () => {
                this.setState({ show_start: true, is_paused: false });
            };

            this.listeners.show_loop = () => {
                this.setState({ show_start: false, show_loop: true });
            };

            this.listeners.set_interval = () => {

                if (this.props.Repetitions < 1) {
                    
                    // Fixed time interval
                    if (this.fixed_time_interval) {
                        clearInterval(this.fixed_time_interval);
                    }
                    
                    this.fixed_time_interval = setInterval(async () => {
                        if (!this.state.is_paused) {

                            component.video.current_time = (component.video.current.duration * component.video.loop_count) + component.video.current.currentTime;
                            
                            // Resolve and stop exercise when time is up
                            if (component.video.current_time >= this.props.Time) {
                                console.log('We are done!');
                                if (this.fixed_time_interval) {
                                    clearInterval(this.fixed_time_interval);
                                }
                                this.setState({ show_start: false, show_loop: false, is_playing: false }, async () => {
                                    await SystemHelper.timeout(2000); // Wait two seconds for fade out
                                    //resolve();
                                    this.props.ExerciseView.done();
                                });
                            }
                        }
                    }, 60);

                }
            };

            
            // Wait for loop in intro and start repetition count when found
            console.log('Loop in intro', this.video.loop_in_intro);
            if (this.video.loop_in_intro) {
                const intro = this.video.elements[0];
                const intro_duration = intro.duration;
                const loop_duration = this.video.elements[1].duration;

                this.video.loop_started = false;

                this.listeners.loop_in_intro = () => {
                    console.log('Checking time for loop...');
                    if (!this.video.loop_started && intro.currentTime > intro_duration - loop_duration) {
                        this.video.loop_started = true;
                        this.video.loop_count = 1;

                        // Set playback rate for loop in intro
                        this.video.elements[0].playbackRate = this.video.loop_speed;

                        console.log('Rep start!');
                        this.props.ExerciseView.setState({ rep_num: 1 });
                        this.startMusic();
                    }
                };

                this.video.elements[0].removeEventListener('timeupdate', this.listeners.loop_in_intro);
                this.video.elements[0].addEventListener('timeupdate', this.listeners.loop_in_intro);
            }

            console.log('STARTING SET', this.props.ExerciseView.state.current_set);

            // Set loop playback rate
            this.video.elements[1].playbackRate = this.video.loop_speed;

            // Remove old intro event
            this.video.elements[0].removeEventListener('play', this.listeners.show_start);

            // Remove old timed exercise event
            this.video.elements[0].removeEventListener('ended', this.listeners.set_interval);

            // Remove old loop next event
            this.video.elements[1].removeEventListener('ended', this.listeners.loop_next);


            // Only play intro on first set
            if (this.props.ExerciseView.state.current_set === 1) {
                this.video.current = this.video.elements[0];

                // Show video when playing so we don't see empty video
                this.video.current.addEventListener('play', this.listeners.show_start);
            }
            else {
                this.video.current = this.video.elements[1];
            }

            // Run intro on first set
            if (this.props.ExerciseView.state.current_set === 1) {
                this.video.current.play();

                if (this.audio_helper) {
                    this.audio_helper.play();
                    this.syncAudio();
                }
            }
            // Else run loop again
            else {
                console.log('START LOOP');
                this.startLoop();
            }

            // Set playing in state
            this.setState({ is_playing: true });

            // We need repetitions
            if (this.props.Repetitions > 0) {

                this.listeners.loop_next = async () => {

                    // Increase count in exercise view if not last
                    if (this.video.loop_count < this.props.Repetitions - 1) {
                        this.props.ExerciseView.setState({ rep_num: this.video.loop_count + 2 });
                    }
            
                    // Wait if delay is set
                    console.log('Waiting', this.props.Delay);
                    if (this.props.Delay && this.props.Delay > 0) {
                        await this.delayRepetition();
                    }

                    // Increase count
                    this.video.loop_count++;

                    console.log('EXERCISE loop', this.video.loop_count);
                    console.log('EXERCISE total', this.props.Repetitions);
            
                    // Check if all repetitions have played
                    if (this.video.loop_count === this.props.Repetitions) {
                        // Hide start and loop
                        this.setState({ show_start: false, show_loop: false, is_playing: false }, async () => {
                            await SystemHelper.timeout(2000); // Wait two seconds for fade out
                            console.log('video done! resolve()');
                            resolve();
                        });
                    }
                    // Else continue loop
                    else {
                        this.video.current.play();
                    }
                };

                // When loop video ended
                this.video.elements[1].addEventListener('ended', this.listeners.loop_next);
            }
            // If length by time
            else {

                // Increment loops played and play again
                this.listeners.loop_next = () => {
                    this.video.current.play();
                    this.video.loop_count++;
                    console.log('timed loops played', this.video.loop_count);
                };

                // When loop video ended
                this.video.elements[1].addEventListener('ended', this.listeners.loop_next);

                // If first set,
                // add listener for intro to start timer
                if (this.props.ExerciseView.state.current_set === 1) {
                    this.video.elements[0].addEventListener('ended', this.listeners.set_interval);
                }
                // Else start timer directly
                else {
                    console.log('Start timer directly');
                    this.listeners.set_interval();
                }
            }
        });

    }

    /**
     * Pause or resume video
     */
    pauseOrResume() {
        if (this.state.is_paused) {
            this.resume();
        } else {
            this.pause();
        }
    }

    /**
     * Pause video
     */
    pause() {
        console.log('Pause exercise', this.video.current);
        if (this.video.current) {
            this.video.current.pause();
            console.log('Video paused', this.video.current);
        }
        if (this.audio_helper) {
            this.audio_helper.pause();
        }

        this.setState({ is_paused: true });
    }

    /**
     * Resume video
     */
    resume() {
        this.setState({ is_paused: false }, () =>  {
            // Resume audio sync
            this.syncAudio();
        });

        if (this.video.current) {
            this.video.current.play();
            // Resume audio if playing intro
            if (this.audio_helper && this.video.current_index === 0) {
                this.audio_helper.resume();
            }
        }
    }

    /**
     * Restart exercise from start
     */
    restart() {

        // Fixed time interval
        if (this.fixed_time_interval) {
            clearInterval(this.fixed_time_interval);
        }

        const last_video_index = this.video.current_index;

        // Video
        this.video.elements[1].pause();
        this.video.current = this.video.elements[0];
        this.video.current_index = 0;
        this.video.current_time = 0;
        this.video.current.currentTime = 0;
        this.video.current.playbackRate = 1;
        this.video.elements[1].currentTime = 0;
        this.video.loop_count = 0;
        this.video.loop_started = false;
        this.video.total_duration = this.getTotalDuration();
        this.video.current.play();

        // Audio
        if (this.audio_helper) {

            if (SystemHelper.isIos()) {
                const path = this.audio_helper.getAudio();
                console.log('Release audio and re-init', path);
                this.audio_helper.release();
                this.audio_helper = new AudioHelper(path);

                // Set volume of instruction
                const volume = this.audio_helper.getVolumeSettings().spoken_instructions;
                this.audio_helper.setVolume(volume / 10);
                console.log('Set instruction volume to ', volume);
            }
            else {
                this.audio_helper.setTime(0);
            }

            console.log('Play audio after restart');
            this.audio_helper.play();

            // Start audio sync if not already running
            // (when index is 0)
            if (last_video_index !== 0) {
                this.syncAudio();
            }
        }

        this.setState({ show_start: true, show_loop: false, show_end: false, is_paused: false });
        this.props.ExerciseView.setState({ rep_num: 0 });
    }

    getCurrentTime() {
        let current_time = 0;

        // On loop
        if (this.video.current_index === 1) {
            
            // Add intro duration on first set
            if (this.props.ExerciseView.state.current_set === 1) {
                current_time = this.video.elements[0].duration;
            }

            // If exercise uses repetitions
            if (this.props.Repetitions > 0) {
                current_time += (this.video.elements[1].duration * this.video.loop_count) +
                    this.video.elements[1].currentTime +
                    this.video.delay_time;
            }
            // Else if exercise uses fixed time
            else {
                current_time += this.video.current_time;
            }
        }
        // On intro
        else if (this.video.current_index === 0) {
            current_time = this.video.elements[0].currentTime;
        }

        //console.log('current_time:' + current_time + ', this.video.current_time: ' + this.video.current_time);

        return current_time;
    }

    getTotalDuration() {
        
        let duration = 0;

        // Add intro duration on first set
        if (this.props.ExerciseView.state.current_set === 1) {
            duration = this.video.elements[0].duration;
        }

        // Check if length is set by reps or time
        if (this.props.Repetitions > 0) {
            duration += (this.video.elements[1].duration * this.props.Repetitions);
            // Add delay
            if (this.props.Delay && this.props.Delay > 0) {
                duration += this.props.Delay * this.props.Repetitions;
            }
        } else {
            duration += this.props.Time;
        }
        return duration;
    }

    /**
     * Unload video to collect memory garbage
     * @param {Determines if the current video blobs should be released} release 
     */
    async unload(release) {
        // Hide video players
        this.setState({
            show_start: false,
            show_loop: false,
            show_end: false
        }, () => {
            if (release) {
                // Release blob URLs
                window.URL.revokeObjectURL(this.video.elements[0].src);
                window.URL.revokeObjectURL(this.video.elements[1].src);
                console.log('Video blobs released');
            }
        });
    }

    initVideoElements() {
        
        this.video.elements = [
            document.querySelector('#video-start'),
            document.querySelector('#video-loop')
        ];

        // Start repetition loop when intro ended
        if (this.video.elements[0]) {
            this.video.elements[0].addEventListener('ended', () => this.startLoop());
        }
    }

    async componentDidMount() {
        // Get config
        await DataHelper.get('config').then(config => {
            this.config = config; 
        });

        // Init video
        this.initVideoElements();
    }

    componentWillUnmount() {
        // Pause and release audio
        setTimeout(() => {
            if (this.audio_helper) {
                console.log('pausing and releasing instruction audio...');
                this.audio_helper.pause();
                this.audio_helper.release();
            } else {
                console.log('cannot pause and release instruction audio...');
            }
        }, 3000);
    }


    render() {
        const exercise_view = this.props.ExerciseView;
        return (
            <>
            <VideoTransform enable={exercise_view.state.user?.meta.allow_modify_instructor || exercise_view.isInstallation} ref={this.video_transform}>
                <video id="video-start" preload="auto" className={'video-element' + (!this.state.show_start ? ' hidden' : '')} playsInline></video>
                <video id="video-loop" preload="auto" className={'video-element' + (!this.state.show_loop ? ' hidden' : '')} muted playsInline></video>
            </VideoTransform>
            
            { this.state.subtitles ?
                <SubtitlesPlayer videoElement={ document.querySelector('#video-start') } subtitleSrc={ this.state.subtitles } />
            : '' }
        </>
        );
    }
}

export default ExerciseVideo;