import i18next from 'i18next';

const SystemHelper = {

    INTERNET_WAS_INTERRUPTED: false,

    /**
     * 
     * @param {The local file path} path 
     * @returns The full system file path
     */
    getSystemPath(path) {
        if (window.cordova) {
            //if (window.device.platform === 'Android') {
                path = window.cordova.file.applicationDirectory + 'www' + path;
            /*} else if (window.device.platform === 'iOS') {
                path = path.substr(1);
            }*/
        }
        return path;
    },

    /**
     * 
     * @param {The local file path} path 
     * @returns The file path in cdvfile:// format
     */
    getCdvPath(path) {
        return 'cdvfile://localhost/persistent/' + path;
    },

    async timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    dayOfWeek(num) {
        const days = [
            i18next.t('common:days.sunday'),
            i18next.t('common:days.monday'),
            i18next.t('common:days.tuesday'),
            i18next.t('common:days.wednesday'),
            i18next.t('common:days.thursday'),
            i18next.t('common:days.friday'),
            i18next.t('common:days.saturday')
        ];
        return days[num];
    },

    /**
     * Safely convert string to date
     * @param {A string in format YYYY-MM-DD hh:mm:ss} dateString 
     * @returns 
     */
    stringToDate(dateString) {
        var parts = dateString.split(/[- :]/); // Split the string into an array using the separator characters: "-", " ", and ":"
        var year = parseInt(parts[0]);
        var month = parseInt(parts[1]) - 1; // Months are zero-indexed, so subtract 1
        var day = parseInt(parts[2]);
        var hour = parseInt(parts[3]);
        var minute = parseInt(parts[4]);
        var second = parseInt(parts[5]);

        // Create the Date object
        return new Date(year, month, day, hour, minute, second);
    },

    /**
     * Returns platform name
     * @returns Platform name (Android/iOS/browser)
     */
    getPlatform() {
        if (window.device) {
            return window.device.platform;
        } else {
            return 'browser';
        }
    },

    /**
     * 
     * @returns Detects if platform is iOS
     */
    isIos() {
        return (window.device && window.device.platform === 'iOS');
    },

    /**
     * 
     * @returns Detects if platform is Android
     */
    isAndroid() {
        return (window.device && window.device.platform === 'Android');
    },

    /**
     * 
     * @returns Detects if platform is browser
     */
    isBrowser() {
        return !window.device;
    },

    /**
     * Detects if user has touchscreen
     * @returns Bool indicating if touchscreen is used
     */
    isTouchScreen() {
        return 'ontouchstart' in window || navigator.msMaxTouchPoints;
    },

    /**
     * Toggle fullscreen (browser)
     */
    toggleFullscreen() {
        if (document.fullscreenElement) {
            document.exitFullscreen()
                .then(() => console.log("Document Exited from Full screen mode"))
                .catch((err) => console.error(err))
            } else {
            document.documentElement.requestFullscreen();
        }
    },

    /**
     * Use a promise to set state that can be awaited
     * @param {The component to set state in} component 
     * @param {Parameters to set} param 
     * @returns A promise that resolves when state is set
     */
    awaitState(component, param) {
        return new Promise((resolve, reject) => {
            if (component) {
                component.setState(param, () => resolve());
            } else {
                reject('Component is not set');
            }
        });
    }

};

export default SystemHelper;