import axios from "axios";
import InstallationHelper from "./InstallationHelper";
import UserHelper from "./UserHelper";

const ApiHelper = {
  Host: localStorage.getItem("host"),
  BasePath: localStorage.getItem("host") + "/api",
  LoginHost: 'https://login.habbie.net',
  //LoginHost: 'https://habbie-login.test',
  //LoginHost: "https://habbie-login.strativ-support.se",
  // LoginHost: "https://login.stage.habbie.net",

  /**
   * Init the API depending on user's API host
   * @param {Manual API host} value
   */
  Init: function (value) {
    console.log("Init API...");

    // Update host in storage
    if (value) {
      localStorage.setItem("host", value);
    }

    let host = value ?? localStorage.getItem("host");

    this.Host = host;
    this.BasePath = host + "/api";

    console.log("Init API with host:", host);
  },

  /**
   * Get request to the API
   * @param {Endpoint path} path
   * @param {Optionally send token manually} token
   */
  Get: async function (path, token) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.BasePath + path, {
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${
              token
                ? token
                : localStorage.getItem("admin_token") === "installation"
                ? InstallationHelper.token.get()
                : UserHelper.token.get()
            }`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },

  /**
   * Post request to the API
   * @param {Endpoint path} path
   * @param {Data to send} data
   */
  Post: async function (path, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.BasePath + path, data, {
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("admin_token") === "installation"
                ? InstallationHelper.token.get()
                : UserHelper.token.get()
            }`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },

  /**
   * Put request to the API
   * @param {Endpoint path} path
   * @param {Data to send} data
   */
  Put: async function (path, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(this.BasePath + path, data, {
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("admin_token") === "installation"
                ? InstallationHelper.token.get()
                : UserHelper.token.get()
            }`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
};

export default ApiHelper;
