import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import SystemHelper from './helpers/SystemHelper';
import FileHelper from './helpers/FileHelper';
import UserHelper from './helpers/UserHelper';
import Button from './shared/Button';
import './Onboarding.scss';

class Onboarding extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.video = null;
        this.audio = null;
    }

    release(media) {
        if (media) {
            media.pause();
            media.src = '';
            media.load();
            media = null;
        }
    }

    skip() {
        this.release(this.video);
        this.release(this.audio);
        this.history.push('/preload');
    }

    componentDidMount() {
        console.log('init onboarding...');

        UserHelper.getUser().then((user) => {
            // Set onboarding finished
            localStorage.setItem('onboarding_finished_' + user.uuid, true);
        });

        const locale = localStorage.getItem('locale') ?? 'en';
        const video_pref = FileHelper.getPlatformVideoPreferences();

        this.video = document.getElementById('onboarding-video');
        this.audio = new Audio(SystemHelper.getSystemPath('/assets/audio/onboarding_' + locale + '.mp3'));
        this.video.src = SystemHelper.getSystemPath('/assets/video/onboarding.' + video_pref.extension);

        this.video.addEventListener('ended', () => {
            this.skip();
        });

        /*this.video.addEventListener('error', () => {
            this.skip();
        });*/
        
        //this.video.addEventListener('canplay', () => { // Not always firing in iOS
            console.log('can play video');
            this.video.play();
            this.audio.play();
            
            // Browser fallback on no interaction
            this.video.addEventListener('click', () => {
                this.video.play();
            });
        //});
    }

    render() {

        const { t } = this.props;

        return (
            <section id="view-onboarding">
                <video id="onboarding-video" playsInline muted />
                <div className="button-bar bottom">
                        <Button className="center blue-dark auto-width" Icon="arrow-right" onClick={() => this.skip()}>{ t('common:skip') }</Button>
                    </div>
            </section>
        );
    }
}

export default withRouter(withTranslation(['common'])(Onboarding));