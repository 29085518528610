import ApiHelper from './ApiHelper';

const PushHelper = {

    /**
     * Init push service
     * @param {The OneSignal App ID} app_id 
     * @param {Method for handle clicks from notifications} opened_handler 
     */
    init(app_id, opened_handler) {
      console.log('OneSignal', JSON.stringify(window.plugins.OneSignal));
      window.plugins.OneSignal.setAppId(app_id);
      window.plugins.OneSignal.setNotificationOpenedHandler(opened_handler);
      
      //Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
      window.plugins.OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
          console.log("User accepted notifications: " + accepted);
      });

      window.plugins.OneSignal.addSubscriptionObserver((state) => {
        if (state.to.isSubscribed) {
          console.log('Subscribed for OneSignal push notifications!')
          // Save device id to user
          PushHelper.addDevice(state.to.userId);
        } else {
          console.log('Unsubscribed for OneSignal push notifications!')
          PushHelper.removeDevice(state.to.userId);
        }
        console.log('Push Subscription state changed: ' + JSON.stringify(state));
      });

      window.plugins.OneSignal.getDeviceState((state) => {
        console.log('Device state', JSON.stringify(state));
        if (state?.userId) {
          PushHelper.addDevice(state.userId);
        }
      });
    },

    /**
     * Add device id to user
     * @param {The device id to add} device_id 
     */
    addDevice: function(device_id) {
      if (device_id) {
        ApiHelper.Post('/user/device_id/add', {
          device_id: device_id
        });
        // Save in locale storage
        localStorage.setItem('device_id', device_id);
      }
    },

    /**
     * Remove device id from user
     * @param {The device id to remove} device_id 
     */
    removeDevice: function(device_id) {
      return new Promise((resolve, reject) => {
        if (device_id) {
          ApiHelper.Post('/user/device_id/remove', {
            device_id: device_id
          }).then(() => {
            // Remove from locale storage
            localStorage.removeItem('device_id');
            resolve()
          });
        } else {
          resolve();
        }
      });
    }

}

export default PushHelper;