import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Button from '../shared/Button';
import ApiHelper from '../helpers/ApiHelper';
import SystemHelper from '../helpers/SystemHelper';
import UserHelper from '../helpers/UserHelper';
import DataHelper from '../helpers/DataHelper';
import './WorkoutIntro.scss';

/**
 * Return exercise preview item
 * @param {Properties from controller} props 
 */
function ExercisePreviews(props) {
    let i = 0;
    let language = i18next.language;
    console.log('lang', language);
    if (props.program.exercises.length > 0) {
        return props.program.exercises.map((ex) => {
            i++;
            return (
                <div className="exercise" key={'exercise-' + i}>
                    <h3>{i}</h3>
                    <h4>{ ex.names && ex.names[language] ? ex.names[language] : ex.name }</h4>
                    <div className="thumb">
                        <img src={ex.image} alt="" />
                    </div>
                </div>
            );
        });
    } else {
        const dummies = [1, 2, 3];
        const items = dummies.map(dummy =>
            <div className="exercise" key={'exercise-' + dummy}>
                <h3><Skeleton width={'30%'} /></h3>
                <h4><Skeleton /></h4>
                <img alt="" />
            </div>
        );
        return items;
    }
}

class WorkoutIntro extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.program = this.props.location.state ? (this.props.location.state.program ?? null) : null;
        this.isInstallation = localStorage.getItem('admin_token') === 'installation';
        this.state = {
            program: { exercises: [] },
            thumbs: [],
            tag_string: null,
            user_name: '',
            user: null,
        }
        this.idle_timeout = null;
        this.config = null;
    }

    /**
     * Get associated tags/aids in a string
     * @param {The current program} program 
     */
    async getTagString(program) {

        let equipment = [];

        // Get translation
        const t = i18next.getFixedT(null, 'workout', null);

        // Get equipment labels
        await ApiHelper.Get('/exercise-equipment').then(labels => {
            equipment = labels.data.data;
            console.log('labels', equipment);    
        }).catch(() => {
            // Old API, no need to do anything
            // Legacy tags will be used
            console.log('No equipment endpoint, using legacy tags...');
        })

        let tags = [], tag_string = '';
        // Loop through exercises and collect tags
        program.exercises.forEach(ex => {
            // Use equipment labels if present
            if (ex.equipment && ex.equipment.length > 0) {
                ex.equipment.forEach(tag => {
                    if (tags.indexOf(tag) < 0 &&
                        // Don't add "no equipment"
                        tag !== '871a04e8-bc0f-42e0-9d7a-da37fc798dcc') {
                        tags.push(tag);
                    }
                });
            }
            // Otherwise use legacy tags of present
            else if (ex.tags && ex.tags.length > 0) {
                ex.tags.forEach(tag => {
                    if (tags.indexOf(tag) < 0) {
                        tags.push(tag);
                    }
                });
            }
        });
        // Loop through tags and concat string
        tags.forEach((tag, i) => {
            let tag_trans = '';

            // Use new labels if present
            if (equipment.length > 0) {
                tag_trans = equipment.find(e => e.uuid === tag).equipment_prefix[i18next.language] + ' ' +
                            equipment.find(e => e.uuid === tag).equipment_names[i18next.language];
            }
            // Or else use legacy tags
            else {
                switch(tag) {
                    case 'chair': tag_trans = 'en stol'; break;
                    case 'table': tag_trans = 'ett bord'; break;
                    case 'mat': tag_trans = 'en matta'; break;
                    case 'wheelchair': tag_trans = 'din rullstol'; break;
                    case 'walker': tag_trans = 'din rollator'; break;
                    default: tag_trans = '';
                }
            }

            if (i < tags.length - 1) {
                tag_string += tag_trans + ', ';
            } else if (tags.length > 1) {
                tag_string = tag_string.substring(0, tag_string.length - 2) + ' ' + t('workout:intro.and') + ' ' + tag_trans;
            } else {
                tag_string += tag_trans;
            }
        });

        if (tag_string.length > 0) {
            tag_string = t('workout:intro.you_need') + ' ' + tag_string.toLowerCase() + '.';
        } else {
            tag_string = t('workout:intro.you_need_no_equipment');
        }
        return tag_string;
    }

    /**
     * Start workout
     */
    start() {
        if (!this.isInstallation) {
            // Register workout start
            ApiHelper.Post('/workouts', { program_uuid: this.state.program.uuid }).then((workout_uuid) => {
                console.log('uuid', workout_uuid);
                // Save workout and program uuid in local storage
                localStorage.setItem('workout_uuid', workout_uuid.data.data);
                localStorage.setItem('program_uuid', this.state.program.uuid);
                // Go to exercise
                this.history.push('/exercise'); 
            });     
        }
    }


    loadUser(){
        const component = this;
        return new Promise(async (resolve) => {
            await UserHelper.getUser().then(async (usr) => {
                console.log('USER', usr);
                component.setState({ user: usr }, () => { resolve(); });
            });
        });
    }

    componentWillUnmount() {
        // Clear timeout interval
        if (this.idle_timeout) {
            clearInterval(this.idle_timeout);
        }
    }

    async componentDidMount() {

        // Get config
        await DataHelper.get('config').then(config => {
            this.config = config;
        });

        // Load user
        if (!this.isInstallation) {
            await this.loadUser();
            this.setState({ user_name: UserHelper.name.get() });
        }

        // Get root path for thumbnails
        /*
        let thumb_root = '';
        await FileHelper.getRootPath().then((path) => {
            console.log('path', path);
            thumb_root = path;
        });*/

        if (this.program != null && this.isInstallation) {
            
            // Save program and properties in state
            await SystemHelper.awaitState(this, {
                program: this.program,
                total_time: this.program.duration,
                tag_string: await this.getTagString(this.program)
            });

            // Get thumbnails
            /*
            this.program.exercises.map(async (ex) => {
                let program = this.program;
                let thumb_path = thumb_root + ex.uuid + '_thumb.png';
                await this.setState(prevState => { return { thumbs: prevState.thumbs.concat(thumb_path) }});

                await FileHelper.get(ex.uuid + '_thumb.png').then(async (file) => {
                    // The file is found
                }).catch(() => {
                    // Image couldn't load
                    // Go to preload
                    this.history.push({
                        pathname: '/preload',
                        state: { program }
                    });
                });
            });*/
        } else {
            UserHelper.getUserProgram().then(async (program) => {
                // Save program and properties in state
                await SystemHelper.awaitState(this, {
                    program: program,
                    total_time: program.duration,
                    tag_string: await this.getTagString(program)
                });

                // Get thumbnails
                /*
                program.exercises.map(async (ex) => {
                    let thumb_path = thumb_root + ex.uuid + '_thumb.png';
                    await this.setState(prevState => { return { thumbs: prevState.thumbs.concat(thumb_path) }});
    
                    await FileHelper.get(ex.uuid + '_thumb.png').then(async (file) => {
                        // The file is found
                    }).catch(() => {
                        console.log('poop');
                        // Image couldn't load
                        // Go to preload
                        this.history.push({
                            pathname: '/preload',
                            state: { program }
                        });
                    });
                });
                */
            }).catch(error => {
                console.error(error);
                // Go back to dashboard if no program
                this.history.push('/dashboard');
            });
        }

        // Abort if no program
        if (!this.state.program) {
            return;
        }

        // Set idle timeout
        // Go to dashboard
        this.idle_timeout = setTimeout(() => {
            this.history.push(this.isInstallation ? '/dashboard-installation' : '/dashboard');
        }, this.config.idle_timeout_minutes * 60000);
    }
    
    render() {

        const { t } = this.props;

        return (
            <SkeletonTheme color="#eecbc5" highlightColor="#eed9d6">
                <section id="view-exercise_intro" className="white">
                    <div className="header">
                        <h3><u>{ t('workout:intro.your_workout') }</u></h3>
                        <h1>{this.state.program.name ? this.state.program.name : <Skeleton />}</h1>
                        <p>{this.state.total_time > 0 && this.state.program.exercises.length > 0 ? ReactHtmlParser(t('workout:intro.workout_duration', { minutes: this.state.total_time, exercises: this.state.program.exercises.length })) : <Skeleton count="3" />}</p>
                        <h4><strong>{this.state.tag_string == null ? <Skeleton /> : this.state.tag_string.length > 0 ? this.state.tag_string : ''}</strong></h4>
                        
                        { this.state.program.settings?.start_instruction ?
                            <div className="card white">
                                <h4>{ this.state.program.settings.start_instruction }</h4>
                            </div>
                        : '' }
                    </div>
                    <div className="exercises-wrapper">
                        <div className="exercises">
                            <ExercisePreviews program={this.state.program} thumbs={this.state.thumbs} />
                        </div>
                    </div>

                    <div className="button-bar bottom">
                        <Button className="yellow-light" onClick={ () => this.isInstallation ? this.history.push('/dashboard-installation') : this.history.push('/dashboard') }>{ t('common:back') }</Button>
                        <Button className="blue-dark" Icon="arrow-right" onClick={() => this.history.push({pathname: '/exercise', state: {program: this.program}})}>{ t('common:start') }</Button>
                    </div>
            </section>    
          </SkeletonTheme>
        );
    }
}

export default withRouter(withTranslation(['workout', 'common'])(WorkoutIntro));