import React, { Component } from 'react';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FileHelper from './helpers/FileHelper';
import UserHelper from './helpers/UserHelper';
import InstallationHelper from './helpers/InstallationHelper';
import DataHelper from './helpers/DataHelper';
import '../styles/_progress-bar.scss';

class Preload extends Component {

    constructor(props) {
        super(props);
        this.is_installation = localStorage.getItem('admin_token') === 'installation';
        this.history = this.props.history;
        this.state = {
            user_program: null,
            total_content_length: 1,
            recieved_content_length: 0
        }
        this.program = (this.props.location.state?.program ?? null);
        this.user = null;
        this.config = null;
    }

    /**
     * Checks if file exists and saves if not
     * @param {Full URL of remote file} remote_path 
     * @param {Path for local storage} local_path 
     */
    async saveIfNotExists(remote_path, local_path) {
        return new Promise(async (resolve, reject) => {
            let file_exists = false;
            let content_length = 0;

            // Abort if no remote file
            if (!remote_path) {
                reject();
            }

            // Check if file already exists
            // and is not changed
            file_exists = await FileHelper.get(local_path).then(async (file) => {

                return new Promise(async (resolve, reject) => {

                    // Is there a file entry?
                    file_exists = file ? true : false;

                    // If there is,
                    // check if remote file has same length as saved file
                    if (file_exists) {
                        // Get size of remote file
                        const remote_length = await this.getContentLength([remote_path]);

                        // Get size of local file
                        await file.getMetadata(metadata => {

                            console.log('Local file size:', metadata.size);
                            console.log('Remote file size:', remote_length);

                            // return false if sizes don't match
                            if (metadata.size === remote_length) {
                                resolve(true)
                            } else {
                                resolve(false);
                            }
                        });
                    }
                    else {
                        resolve(false);
                    }
                });
            });
            
            // Save locally if file doesn't exist
            if (file_exists) {
                console.log('file already exists', local_path);
                resolve();
            } else {
                console.log('Saving ' + remote_path + ' to ' + local_path);
                
                // Save start
                FileHelper.save(remote_path, local_path, async (current) => {
                    // Update progress
                    this.setState(prevState => { return {
                        recieved_content_length: prevState.recieved_content_length + current
                    }});
                    content_length += current;
                    //console.log('current', current);
                    console.log('Recieved ' + this.state.recieved_content_length + ' of ' + this.state.total_content_length);
                }).then(() => {
                    console.log('saved ' + remote_path + ' to ' + local_path + '... (' + content_length + ')');
                    resolve();
                });
            }
        });
    }

    async getContentLength(paths) {
        const request = new Promise(async (resolve, reject) => {
            await FileHelper.getContentLength(paths).then((length) => {
                resolve(length);
            }).catch((error) => {
                console.error(JSON.stringify(error));
                alert('Ett fel uppstod: ' + error);
                window.location.reload(true);
            });
        });
        return request;
    }

    async componentDidMount() {
        // Get user
        if (!this.is_installation) {
            await UserHelper.getUser().then(user => {
                if (!user) {
                    UserHelper.logout(this.history);
                    return;
                } else {
                    this.user = user;
                }
            }).catch(error => {
                console.error(error);
                UserHelper.logout(this.history);
            });

            // Abort if no user
            if (!this.user) {
                return;
            }
        }
        

        // Get config
        await DataHelper.get('config').then((config) => {
            this.config = config;
        });
        
        // Get exercises from user program
        let user_exercises = null;
        if (this.program != null && this.is_installation) {
            console.log('installation program', this.program);
            user_exercises = this.program.exercises;
        } else if (!this.is_installation) {
            await UserHelper.getUserProgram().then(async (program) => {
                user_exercises = program.exercises;
            }).catch(error => {
                // If no program is present
                console.error(error);
                // go to dashboard directly and handle it there
                //this.history.push('/update-app');
                this.history.push('/dashboard');
            });
        } else {
            await InstallationHelper.getPrograms().then(async (programs) => {
                user_exercises = Array.from(new Set(programs.flatMap(program => program.exercises)));
            }).catch(error => {
                // If no program is present
                console.error(error);
                // go to dashboard directly and handle it there
                //this.history.push('/update-app');
                this.history.push('/dashboard-installation');
            });
        }

        console.log('EXERCISES', user_exercises);

        // Abort if no exercises
        if (!user_exercises) {
            console.log('no user exercises');
            this.history.push(this.is_installation ? '/dashboard-installation' : '/dashboard');
            return;
        }

        // Get video preferences for platform
        let video_pref = FileHelper.getPlatformVideoPreferences();

        // Set total content length
        let total_content_length;
        if (video_pref.extension === 'webm') {
            console.log('Getting start video...');
            total_content_length = await this.getContentLength(user_exercises.map(x => x.video.webm.start));
            console.log('Getting loop video...');
            total_content_length += await this.getContentLength(user_exercises.map(x => { return x.video.webm.loop.standard; }));
        } else {
            total_content_length = await this.getContentLength(user_exercises.map(x => x.video.hevc.start));
            total_content_length += await this.getContentLength(user_exercises.map(x => { return x.video.hevc.loop.standard; }));
        }

        console.log('Getting audio...');

        // Get subtitles if exists
        total_content_length += await this.getContentLength(user_exercises.map(x => (x.audio && x.audio[i18next.language] ? x.audio[i18next.language] : null)));

        //console.log('Getting thumbnails...');

        // Get thumbnail image
        //total_content_length += await this.getContentLength(user_exercises.map(x => x.image));

        console.log('Getting subtitles...');

        // Get subtitles if exists
        total_content_length += await this.getContentLength(user_exercises.map(x => (x.subtitles_loc && x.subtitles_loc[i18next.language] ? x.subtitles_loc[i18next.language] : x.subtitles)));

        console.log('--- TOTAL length is ', total_content_length);

        // Set total length
        this.setState({ total_content_length: total_content_length });

        // Loop through exercises and save videos and images
        for (let i = 0; i < user_exercises.length; i++) {
            const video = user_exercises[i].video;

            let file_start, file_loop;
            // Dowbload webm
            if (video_pref.extension === 'webm') {
                file_start = video.webm.start;
                file_loop = video.webm.loop.standard;
            }
            // Download hevc
            else {
                file_start = video.hevc.start;
                file_loop = video.hevc.loop.standard;
            }

            // Save videos and images
            await this.saveIfNotExists(file_start, user_exercises[i].uuid + '_start.' + video_pref.extension);
            await this.saveIfNotExists(file_loop, user_exercises[i].uuid + '_loop.' + video_pref.extension);
            
            // Save audio
            if (user_exercises[i].audio && user_exercises[i].audio[i18next.language]) {
                let audio = user_exercises[i].audio[i18next.language];
                await this.saveIfNotExists(audio, user_exercises[i].uuid + '_audio_' + i18next.language + '.mp3');
            } else {
                console.log('No audio...');
            }
            
            // Save thumbnails
            // We load these directly now
            //await this.saveIfNotExists(user_exercises[i].image, user_exercises[i].uuid + '_thumb.png');
            

            // Save subtitles if they should be used
            let subtitles = null;
            if (user_exercises[i].subtitles_loc && user_exercises[i].subtitles_loc[i18next.language]) {
                subtitles = user_exercises[i].subtitles_loc[i18next.language];
            } else {
                subtitles = user_exercises[i].subtitles;
            }
            if (subtitles !== null) {
                await this.saveIfNotExists(subtitles, user_exercises[i].uuid + '_subtitles_' + i18next.language + '.vtt');
            }
        }

        console.log('DONE');

        // Set full progress if everything is cached
        if (this.state.recieved_content_length === 0) {
            this.setState({ recieved_content_length: this.state.total_content_length });
        }

        // Go to app update (and then dashboard) when finished
        // Wait 500 ms for smoothness
        //setTimeout(() => this.history.push('/update-app'), 500);
        // Go directly to dashboard/exercise since APK update is not allowed on Google Play anymore
        
        if (this.props.location.state?.program) {
            console.log('We have a program so going to exercise...');
            this.history.push({
                pathname: '/exercise',
                state: {
                    program: this.program
                }
            });
        }
        // Else go to dashboard
        else {
            console.log('We have no program, going to dashboard...');
            this.history.push(this.is_installation ? '/dashboard-installation' : '/dashboard');
        }
    }

    render() {

        const { t } = this.props;

        let progress_perc = (this.state.recieved_content_length / this.state.total_content_length * 100);
        // TODO: Fix bug where recieved_content_length > total_content_length
        if (progress_perc > 100) {
            progress_perc = 100;
        }
        return (
            <section id="view-splash" className="blobs">
                <div className="wrapper">
                    <h2>{ (progress_perc > 0.1 ? t('preload:getting_exercises') : t('preload:checking_exercises')) }...</h2>
                    { progress_perc > 0.1 &&
                        <>
                            <div className="progress-bar small inverted">
                            <div className="progress">
                                <div className="item current"><div className="bar"><div className="time" style={{ width: progress_perc + '%' }}></div></div></div>
                            </div>
                            </div>
                        </>
                    }
                </div>
            </section>
        );
    }
}

export default withRouter(withTranslation(['preload'])(Preload));