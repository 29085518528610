import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import UserHelper from '../helpers/UserHelper';
import InstallationHelper from '../helpers/InstallationHelper';
import Button from '../shared/Button';
import './NumericLogin.scss';

class NumericLogin extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.code_length = 6;
        this.state = {
            input: '',
            visible_input: this.fillBullets(''),
            error: '',
            //is_admin_authenticated: false
        }
    }

    setDefaultSettings() {
        let audio_volume = localStorage.getItem('audio_volume');
        let reminder_time = localStorage.getItem('reminder_time');

        if (!audio_volume) {
            localStorage.setItem('audio_volume', '10');
        }
        if (!reminder_time) {
            localStorage.setItem('reminder_time', '10:00');
        }
    }

    login() {
        // Has a previous login token
        if(localStorage.getItem("admin_token") !== null) {
            
            // Send all login requests to common endpoint
            UserHelper.login(this.state.input, localStorage.getItem("admin_token")).then((res) => {
                
                // If login is user
                if(localStorage.getItem("admin_token") !== 'installation') {
                    
                    // Save token and code in local storage
                    UserHelper.token.set(res.data.data.token);
                    UserHelper.code.set(this.state.input);

                    UserHelper.getUser().then((user) => {
                        console.log('USER', user);
                        UserHelper.name.set(user.first_name);
                        this.setDefaultSettings();
                        // Run onboarding if user hasn't seen it yet
                        if (localStorage.getItem('onboarding_finished_' + user.uuid)) {
                            this.history.push('/preload');
                        } else {
                            this.history.push('/onboarding');
                        }
                    }).catch((error) => {
                        console.error('User not found', error);
                        UserHelper.logout(this.history);
                    });
                } else {
                    // Save token and code in local storage
                    InstallationHelper.token.set(res.data.data.token);
                    InstallationHelper.code.set(this.state.input);

                    InstallationHelper.getInstallation().then((installation) => {
                        console.log('INSTALLATION', installation);
                        InstallationHelper.name.set(installation.name);
                        this.setDefaultSettings();

                        this.history.push('/dashboard-installation');
                    });
                }
            }).catch((error) => {
                // Show error
                console.error('error', error);
                const t = i18next.getFixedT(null, 'login', null);
                this.setState({ input: '', visible_input: this.fillBullets(''), error: t('login:numeric.verification.wrong_code') });
            });
        } 
        // Fresh login
        else {
            // Get translation
            const t = i18next.getFixedT(null, 'login', null);

            // Send api request to send code to admin
            UserHelper.requestStaffCode(this.state.input).then((res) => {
                // Redirect immediately to staff code verification
                this.history.push({
                    pathname: '/staff-code-verification',
                    code: this.state.input,
                    // If this is a demo user, admin code is received here
                    admin_code: (res.data.data ? res.data.data.admin_code : null)
                });
            }).catch((error) => {
                // Show error
                console.error(error);
                this.setState({ input: '', visible_input: this.fillBullets(''), error: t('login:numeric.login.wrong_code') });
            });
        }
    }

    /**
     * Logout admin
     */
    logoutAdmin() {
        UserHelper.logoutAdmin();
        this.setState({ is_admin_authenticated: false });
    }

    fillBullets(input) {
        let bullets = '';
        for (let i = 0; i < this.code_length - input.length; i++) {
            bullets += '•';
        }
        return input + bullets;
    }

    clickNum(num) {
        this.setState({ error: '' });
        if (this.state.input.length < this.code_length) {
            this.setState(prevState => { return { input: prevState.input + num }}, function() {
                this.setState({ visible_input: this.fillBullets(this.state.input) });
            });
        }
    }

    backspace() {
        if (this.state.input.length > 0) {
            this.setState(prevState => { return { input: prevState.input.substr(0, prevState.input.length - 1) }}, function() {
                this.setState({ visible_input: this.fillBullets(this.state.input) });
            });
        }
    }

    handleKeyPress(component, event) {
        if (event) {
            if (!isNaN(event.key)) {
                component.clickNum(event.key);
            }
            else if (event.key === 'Backspace') {
                component.backspace();
            } else if (event.key === 'Enter') {
                component.login();
            }
        }
    }

    componentDidMount() {
        // Check if admin is authenticated
        //if (localStorage.getItem("admin_token")) {
        //    this.setState({ is_admin_authenticated: true });
        //}

        // Listen for keyboard events
        document.addEventListener('keydown', (event) => { this.handleKeyPress(this, event) });
    }

    render() {

        const { t } = this.props;

        return (
            <section id="view-login" className="white">
                <div className="header blobs">
                    <div id="login-input">{ this.state.visible_input }</div>
                </div>
                <h3>{ t('login:numeric.login.heading') }</h3>
                <div className="numpad">
                    <div className="row">
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('1')}>1</Button>
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('2')}>2</Button>
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('3')}>3</Button>
                    </div>
                    <div className="row">
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('4')}>4</Button>
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('5')}>5</Button>
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('6')}>6</Button>
                    </div>
                    <div className="row">
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('7')}>7</Button>
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('8')}>8</Button>
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('9')}>9</Button>
                    </div>
                    <div className="row">
                        <Button className="circle numpad-number" onClick={ () => this.clickNum('0')}>0</Button>
                        <Button className="backspace" onClick={ () => this.backspace()}></Button>
                    </div>
                </div>
                <div className="error">{ this.state.error }</div>
                <div className="button-bar bottom">
                { this.state.is_admin_authenticated ? <Button onClick={ () => this.logoutAdmin() }>{ t('login:numeric.login.logout_staff') }</Button> : '' }
                    <Button className="yellow-light auto-width" onClick={() => this.history.push('/login')}>{ t('common:back') }</Button>
                    <Button className="blue-dark auto-width" Icon="arrow-right" onClick={() => this.login()}>{ t('login:numeric.login.login') }</Button>
                </div>
          </section>
        );
    }
}

export default withRouter(withTranslation(['login', 'common'])(NumericLogin));
